<template>
  <Card class="content-copy">
    <component
      :is="content ? 'button' : 'div'"
      class="flex w-full justify-between gap-4 items-center px-4 py-4 border-transparent text-left focus:outline-none cursor-pointer"
      :class="color && open ? `border-brand-${color}-300` : ''"
      :aria-expanded="content ? open : null"
      :aria-controls="content ? `${uuid}-${id}` : null"
      v-on="content ? { click: toggle } : {}"
    >
      <h5 class="flex flex-1 flex-col sm:flex-row gap-2 leading-6">
        <span>{{ title }}</span>
        <!-- NB: subtitle is missing in MLP API -->
        <span v-if="subtitle" class="text-brand-grey-600 sm:ml-auto">{{
          subtitle
        }}</span>
      </h5>
      <Icon
        v-if="content"
        name="IconChevronDown"
        class="transform transition-all duration-200"
        :class="open ? 'rotate-180' : ''"
      />
    </component>
    <div
      v-if="content"
      :id="`${uuid}-${id}`"
      class="p-3 sm:text-base accordion--content copy"
      :class="[open ? 'block' : 'hidden']"
      v-html="$md.render(content)"
    ></div>
  </Card>
</template>

<script setup lang="ts">
export interface AccordionItemProps {
  title?: string
  subtitle?: string
  content?: string
  color?: string
  active?: number | null
  uuid?: string | null
  id?: number | null
}

const props = withDefaults(defineProps<AccordionItemProps>(), {
  title: '',
  subtitle: '',
  content: '',
  color: '',
  active: null,
  uuid: null,
  id: null
})

const emits = defineEmits<{
  toggle: [id: number | null]
}>()

const open = computed(() => {
  return props.active === props.id
})

function toggle() {
  emits('toggle', props.id)
}
</script>

<style lang="postcss" scoped>
.accordion--content :deep(p) {
  @apply max-w-none mb-4;
}
</style>
